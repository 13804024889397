import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {DeleteModalComponent} from '../delete-modal/delete-modal.component';
import {ApiService} from "../services/api.service";
import {environment} from "../../environments/environment";


interface Region {
    value: string;
    regionValue: string;
}

@Component({
    selector: 'app-replicate-modal',
    templateUrl: './replicate-modal.component.html',
    styleUrls: ['./replicate-modal.component.scss']
})
export class ReplicateModalComponent {
    status: boolean = false
    formfill: boolean = false
    output: any
    matDialogDeleteRef: MatDialogRef<ConfirmModalComponent> | undefined;
    checkedList: any[];
    errormsg: boolean = false;
    regions= environment.Region;
    form: FormGroup;
    submitted: any;
    replicateToken: any;
    tokenReplicationStatus: any;
    kmsReplicationStatus: any;
    replicate: boolean = false;
    eMsg: string = "";
    replicationFail: boolean = false;
    replicateArnfailure: any;
    replicateArnError: any;
    accountName: any;
    replicatedTokenResponse: any;
    replicatedTokenRegionList: any;
    replicatedTokensStatus: any;
    regionInitial: string = '';
    replicationStatusMsg = false;
    replicateTokenData: any;
    repFailMsg: any;
    errorMsg: any;
    replicationStatusErrorMsg: boolean = false;
    repStatusError: any;
    tokenForm: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: string,
        private apiService: ApiService,
        private _mdr: MatDialogRef<ReplicateModalComponent>,
        private formBuilder: FormBuilder,
        private matDialog: MatDialog, private dialogRef: MatDialogRef<DeleteModalComponent>
    ) {
        this.output = data
        this.checkedList = [];
        this.form = new FormGroup({
            region: new FormControl('', Validators.required),
            tokenArn: new FormControl(this.output.token)
        });
    }

    get formValues() {
        return this.form.controls;
    }

    ngOnInit() {
        this.tokenForm = this.formBuilder.group({
            tokenArn: [this.output.token]
        });
        this.tokenForm.controls['tokenArn'].disable()
    }

    CloseDialog() {
        this._mdr.close(false);
    }

    setenv_name() {
        if (this.output.account === "Dev") {
            this.accountName = "dev"
        } else if (this.output.account === "Dev-dp") {
            this.accountName = "dev-dp"
        } else {
            this.accountName = 'stg/prod'
        }
    }

    //status
    checkArnStatus() {
        this.status = true
        this.formfill = false
        this.setenv_name();
        this.apiService.replicateTokenStatus(this.output.token, this.accountName).subscribe(response => {
            this.replicatedTokenResponse = response;
            this.replicateTokenData = Object.keys(this.replicatedTokenResponse).length;
            if (this.replicateTokenData !== 0) {
                Object.values(this.replicatedTokenResponse).forEach((value) => {
                    this.replicatedTokenRegionList = value;
                });
                if (this.replicatedTokenRegionList.token) {
                    this.replicatedTokensStatus = this.replicatedTokenRegionList.token;
                    this.replicationStatusMsg = false;  //token not replicated to any region - empty response
                    this.replicationStatusErrorMsg = false;
                } else {
                    this.repStatusError = this.replicatedTokenRegionList.errorMessage; //error msg while checking arn status
                    this.replicationStatusMsg = false;
                    this.replicate = false;     //replication region error msg
                    this.replicationStatusErrorMsg = true; //error msg
                }
            } else
              {
                this.replicationStatusMsg = true; //token not replicated to any region empty response
                this.replicate = false;     //replication region error msg
                this.replicationStatusErrorMsg = false; //error msg while checking replication status

              }
        }, (error: any) => {
            console.log('Arn Status Error', error)
            this.eMsg = error.errorMessage;
            this.errormsg = true;
        });
    }

    applyReplicate() {
        this.status = false
        this.formfill = true
    }

    //Replicate token
    replicateTokenForm(data: any) {
        this.setenv_name();
        if (this.form.valid) {
            this.submitted = true;
            this.apiService.replicateToken(data, this.accountName).subscribe(response => {
                    this.replicateToken = response;
                    this.tokenReplicationStatus = this.replicateToken.tokenReplicationStatus;
                    this.kmsReplicationStatus = this.replicateToken.kmsReplicationStatus;
                    this.replicateArnfailure = this.replicateToken.failures;
                    this.replicateArnError = this.replicateToken.errorMessage;
                    if (this.tokenReplicationStatus) {
                        this.replicate = false; //not replicate msg
                        this.replicationFail = false; //failure msg
                        this.regionInitial = ''; //clear selected values
                        this.matDialogDeleteRef = this.matDialog.open(ConfirmModalComponent, {
                            data: {
                                operation: "Replicate"
                            },
                            disableClose: true
                        });
                    } else {
                        if (this.replicateArnfailure) {
                            this.regionInitial = '';
                            this.replicate = false;  //error msg from reponse
                            this.replicationFail = true;
                            this.repFailMsg = this.replicateArnfailure;
                        } else {
                            this.regionInitial = '';
                            this.replicate = true; //display error msg
                            this.replicationFail = false;
                            this.eMsg = this.replicateArnError;
                            console.log("Replication Failed", this.eMsg);
                        }
                    }
                    ;
                },
                (error: any) => {
                    console.log('Replication Error', error)
                    this.errorMsg = error.errorMessage;
                    this.errormsg = true;
                }
            );
        }
    }
}
