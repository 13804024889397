<div class="loader-container" *ngIf="loadbar">
    <mat-progress-bar color="black" mode="indeterminate"></mat-progress-bar>
</div>
<br>
<mat-tab-group (selectedTabChange)="selectedTabChange($event)">
    <div class="loader-container" *ngIf="m365Data">
        <mat-progress-bar color="black" mode="indeterminate"></mat-progress-bar>
    </div><br>
    <mat-tab label="Provider : M365">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <br>
        <div style="display:flex; flex-direction: row;">
            <div class="form-group">
                <input style="margin-left:6px;width:450px;height:40px" size="10" (keyup)="applyFilter($event)"
                    name="sender" id="classId" type="text" formControlName="sender" class="form-control"
                    placeholder="Search" required>
            </div>&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="form-group"  *ngIf="this.isProd" >
                <select name="selectedQuantity" id="aantal" style="width:450px;height:45px" (change)="onChange($event)">
                   <option *ngFor="let option of regions" [value]="option.regionValue">
                        {{option.regionValue}}
                   </option>
                </select>
            </div>
        </div>
        <br>
        <div class="table-responsive mat-elevation-z8">
            <div class="loader-container" *ngIf="m365Data">
                <span style="margin-left:700px;color: blue;">Certificates are loading...</span>
                <mat-progress-bar color="black" mode="indeterminate"></mat-progress-bar>
             </div>
            <table mat-table [dataSource]="this.appswithM365CerificateDataSource" matSort
                (matSortChange)="sortData('M365')">
                <ng-container matColumnDef="Name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Product Name</th>
                    <td *matCellDef="let y" mat-cell>{{y.productName}}</td>
                </ng-container>
                <ng-container matColumnDef="Class Id">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Class Id</th>
                    <td *matCellDef="let y" mat-cell>{{y.classId}}</td>
                </ng-container>
                <ng-container matColumnDef="Template Id">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Template Id</th>
                    <td *matCellDef="let y" mat-cell>{{y.templateId}}</td>
                </ng-container>
                <ng-container matColumnDef="CertificateExpiryDate">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Cerificate Expiry Date</th>
                    <td *matCellDef="let y" mat-cell>
                        <div class="align" *ngIf="y.providerKeyList">
                            <div *ngFor="let a of y.providerKeyList">
                                {{a.expiresAt.seconds * 1000 | date: 'yyyy/MM/dd h:mm:ss a zzzz'}}
                            </div>
                        </div>
                        <div class="align" *ngIf="!y.providerKeyList">
                            NULL
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="APP Config Info">
                    <th mat-header-cell *matHeaderCellDef> App Config Info </th>
                    <td mat-cell *matCellDef="let y" style="text-align: center;">
                        <mat-icon color="primary" (click)="AppConfigInfo(y.classId,y.templateId)">visibility</mat-icon>
                    </td>
                </ng-container>
                <ng-container matColumnDef="APP Redirect Domain Info">
                    <th mat-header-cell *matHeaderCellDef> App Redirect Info </th>
                    <td mat-cell *matCellDef="let y" style="text-align: center;">
                        <mat-icon color="primary" (click)="AppRedirectInfo(y.classId)">visibility</mat-icon>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Certificate Details">
                    <th mat-header-cell *matHeaderCellDef> Certificate Details </th>
                    <td mat-cell *matCellDef="let y" style="text-align: center;">
                        <mat-icon color="accent" (click)="AppCertificateInfo(y.classId,y.templateId,y.provider)">
                            description</mat-icon>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-tab>

    <mat-tab label="Provider : GWS">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <br>
        <div style="display:flex; flex-direction: row;">
            <div class="form-group">
                <input style="margin-left:6px;width:450px;height:40px;" size="10" (keyup)="applyGWSFilter($event)"
                    name="sender" id="GWSClassId" type="text" formControlName="sender" class="form-control"
                    placeholder="Search" required>
            </div>&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="form-group"  *ngIf="this.isProd" >
            <select name="selectedQuantity" id="aantal" style="width:450px;height:45px" (change)="onChange($event)">
               <option *ngFor="let option of regions" [value]="option.regionValue">
                    {{option.regionValue}}
               </option>
            </select>
        </div>
        <br><br>
        </div>
        <br>
        <div class="table-responsive mat-elevation-z8">
            <div class="loader-container" *ngIf="gwsData">
                <span style="margin-left:700px;color: blue;">Certificates are loading...</span>
                <mat-progress-bar color="black" mode="indeterminate"></mat-progress-bar>
            </div>
            <table mat-table [dataSource]="this.appswithGWSCerificateDataSource" matSort
                (matSortChange)="sortData('GWS')">
                <ng-container matColumnDef="Name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Product Name</th>
                    <td *matCellDef="let y" mat-cell>{{y.productName}}</td>
                </ng-container>
                <ng-container matColumnDef="Class Id">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Class Id</th>
                    <td *matCellDef="let y" mat-cell>{{y.classId}}</td>
                </ng-container>
                <ng-container matColumnDef="Template Id">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Template Id</th>
                    <td *matCellDef="let y" mat-cell>{{y.templateId}}</td>
                </ng-container>
                <ng-container matColumnDef="MasterAccId">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>MasterAccountId</th>
                    <td *matCellDef="let y" mat-cell>
                        <p *ngIf="y.MasterServiceAccId">{{y.MasterServiceAccId}}</p>
                        <p *ngIf="!y.MasterServiceAccId">NULL </p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="MasterAccIdExpirtDate">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>MasterAccountExpiry</th>
                    <td *matCellDef="let y" mat-cell>
                        <div class="align" *ngIf="y.masterSmCertificates">
                            <div *ngFor="let a of y.masterSmCertificates">
                                {{a.keyPairAndCertificate.endDate}}
                            </div>
                        </div>
                        <div class="align" *ngIf="!y.masterSmCertificates">
                            NULL
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="APP Config Info">
                    <th mat-header-cell *matHeaderCellDef> App Config Info </th>
                    <td mat-cell *matCellDef="let y" style="text-align: center;">
                        <mat-icon color="primary" (click)="AppConfigInfo(y.classId,y.templateId)">visibility</mat-icon>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Certificate Details">
                    <th mat-header-cell *matHeaderCellDef> Certificate Details </th>
                    <td mat-cell *matCellDef="let y" style="text-align: center;">
                        <mat-icon color="accent" (click)="AppCertificateInfo(y.classId,y.templateId,y.provider)">
                            description</mat-icon>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedGWSColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedGWSColumns;" mat-row></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
        <br>
        <span *ngIf="this.eMsg" style="margin-left:450px;color: red;size:small;">Please click on Reload Cert</span>
        <button  *ngIf="this.eMsg" style="margin-left:30px;margin-right: 20px;background-color:dodgerblue ;color:white"
        (click)="reloadCertPage()">Reload Cert</button>
    </mat-tab>

    <mat-tab label="Provider : SLACK">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <br>
        <div style="display:flex; flex-direction: row;">
            <div class="form-group">
                <input style="margin-left:20px;width:300px;" size="10" (keyup)="applyFilter($event)" name="sender"
                    id="sender" type="text" formControlName="sender" class="form-control" placeholder="Search" required>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>