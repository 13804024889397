import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  environment
} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HecateService {
  accesstoken: any;
  authCode: any;
  private TOKEN_URL = environment.Token_URL;
  private Client_id = environment.client_id;
  private Client_secret = environment.secret_key;
  private redirect_uri:string = environment.redirect_uri;
  private Hecate_Base_Url = environment.Hecate_Base_Url;
    classId:any;
    templateId:any;
    m365Config: any;
    provider: any;
  constructor(private http: HttpClient, private router: Router) {
  }

   //++++++ Get Token+++++++//
   getTokens() {
    this.authCode = localStorage.getItem("authCode");
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(
        'Content-Type', 'application/x-www-form-urlencoded'
    );
    let params = new HttpParams()
        .set('code', this.authCode)
        .set('grant_type', 'authorization_code')
        .set('redirect_uri', this.redirect_uri)
        .set('client_id', this.Client_id)
        .set('client_secret', this.Client_secret)
        .set('scope', 'ctp-static-tokens-api/write ctp-static-tokens-api/read');
    const httpOptions = {
      params: params,
      headers: headers
    };
    return this.http.post(this.TOKEN_URL + '/token', null, httpOptions);
  }

    //++++++++++++++Get Apps List++++++++++++++++++++++//
    public getAppsList(regionValue:string) {
      if(environment.env_name==='dev' && regionValue!== null){
        this.checkBaseURLBasedOnRegion(regionValue);
      }
      this.accesstoken = localStorage.getItem("tokenData");
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Authorization', this.accesstoken);
      headers = headers.append('Content-Type', 'application/json');
      const httpOptions = {
          headers: headers,
      };
      return this.http.get <any> (this.Hecate_Base_Url + '/list/appInfo', httpOptions);
    }

    //+++++++++++++Get App Config ++++++++++++++++++++//
    public getAppConfig(classId,templateId,regionValue){
      if(environment.env_name==='prod' && regionValue!== null){
        this.checkBaseURLBasedOnRegion(regionValue);
      }
        this.accesstoken = localStorage.getItem("tokenData");
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', this.accesstoken);
        headers = headers.append('Content-Type', 'application/json')

        let params = new HttpParams().set('classId',classId )
                                .set('templateId',templateId);
        const httpOptions = {
            headers: headers,
            params:params
        };
        return this.http.get(this.Hecate_Base_Url + '/list/appConfig',httpOptions);
    }

    //+++++++++++++Get Redirect Domain Config ++++++++++++++++++++//
    public getRedirectInfo(classId,regionValue){
      if(environment.env_name==='prod' && regionValue!== null){
        this.checkBaseURLBasedOnRegion(regionValue);
      }
        this.accesstoken = localStorage.getItem("tokenData");
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', this.accesstoken);
        headers = headers.append('Content-Type', 'application/json')
        let params = new HttpParams().set('classId',classId );
        const httpOptions = {
            headers: headers,
            params:params
        };
        return this.http.get(this.Hecate_Base_Url + '/list/redirectDomain',httpOptions);
    }

     //++++++++++++++Get All  M365 Certificate List++++++++++++++++++++++//
    
     public getAllM365CertList(regionValue:string) {
      if(environment.env_name==='prod' && regionValue!== null){
        this.checkBaseURLBasedOnRegion(regionValue);
      }
      this.accesstoken = localStorage.getItem("tokenData");
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Authorization', this.accesstoken);
      headers = headers.append('Content-Type', 'application/json');
      const httpOptions = {
          headers: headers
      };
      console.log(httpOptions);
      return this.http.get <any> (this.Hecate_Base_Url + '/certificates/m365Certs', httpOptions);
  }

    //++++++++++++++Get All  GWS Certificate List++++++++++++++++++++++//
    
    public getAllGWSCertList(regionValue:string) {
      if(environment.env_name==='prod' && regionValue!== null){
        this.checkBaseURLBasedOnRegion(regionValue);
      }
      this.accesstoken = localStorage.getItem("tokenData");
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Authorization', this.accesstoken);
      headers = headers.append('Content-Type', 'application/json');
      const httpOptions = {
          headers: headers
      };
      console.log(httpOptions);

   return this.http.get <any> (this.Hecate_Base_Url + '/certificates/gwsCerts', httpOptions);
  }

       //+++++++++++++Get Cert details ++++++++++++++++++++//
       public getCertDetailsInfo(classId,templateId,regionValue){
        if(environment.env_name==='prod' && regionValue!== null){
          this.checkBaseURLBasedOnRegion(regionValue);
        }
        this.accesstoken = localStorage.getItem("tokenData");
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', this.accesstoken);
        headers = headers.append('Content-Type', 'application/json')

        let params = new HttpParams().set('classId',classId )
                                .set('templateId',templateId);
        const httpOptions = {
            headers: headers,
            params:params
        };
        return this.http.get(this.Hecate_Base_Url + '/certificates/certDetails',httpOptions);
    }

    checkBaseURLBasedOnRegion(regionValue:String){
         switch(regionValue){
           case "eu-central-1":
            {
              this.Hecate_Base_Url=environment.Hecate_eu_central_1_Url;
              break;
            }
            case "us-east-1":
           {
             this.Hecate_Base_Url=environment.Hecate_us_east_1_Url;
             break;
            }
             case "ca-central-1":
            {
             this.Hecate_Base_Url=environment.Hecate_ca_central_1_Url;
             break;
            }
            case "af-south-1":
            {
             this.Hecate_Base_Url=environment.Hecate_af_south_1_Url;
             break;
            }
             case "us-west-2":
            {
             this.Hecate_Base_Url=environment.Hecate_us_west_2_Url;
              break;
            }
            case "ap-southeast-2":
            {
             this.Hecate_Base_Url=environment.Hecate_ap_southeast_2_Url;
             break;
            }
            default:
            this.Hecate_Base_Url=environment.Hecate_Base_Url;
        }
     }
}