import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CreateStaticTokenComponent} from './create-static-token/create-static-token.component';
import {HomeComponent} from './home/home.component';
import {ListStaticTokenComponent} from './list-static-token/list-static-token.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './shared/auth.guard';
import { AppsListComponent } from './Hecate_2.0/apps-list/apps-list.component';

const routes: Routes = [
    {path: 'createToken', component: CreateStaticTokenComponent, canActivate: [AuthGuard]},
    {path: 'listOfToken', component: ListStaticTokenComponent, canActivate: [AuthGuard]},
    {path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    {path: 'logout', component: LoginComponent},
    {path: 'login', component: LoginComponent},
    {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
    {path: 'appsList', component: AppsListComponent, canActivate: [AuthGuard]}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
