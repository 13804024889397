<div class="header">
    <img height="20px" src="assets/images/mimecast-logo.svg" style="margin-right: 20px;" width="200px"/>
    <label *ngIf="isDev" style="color: white; font-size:large;">Env: DEV</label>
    <label *ngIf="isStg" style="color: white; font-size: large; ">Env: STG</label>
    <label *ngIf="isProd" style="color: white; font-size: large; ">Env: PROD</label>
    <button *ngIf="auth.isLoggedIn()"
            color="primary"
            mat-flat-button routerLink="/home" style="margin-left:100px;margin-right: 10px;margin-top: 20px;margin-bottom: 10px;height:20px;width:80px">Home
    </button>
    <div class="header-right">
        <button (click)="auth.logout()"
                *ngIf="auth.isLoggedIn()" color="primary" mat-flat-button style="margin-left:50px;margin-right: 20px;margin-top: 20px; margin-bottom: 10px;height: 20px;width:80px">Sign out
        </button>
    </div>
</div> 