import {
  Component,
  Inject
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  ConfirmModalComponent
} from '../confirm-modal/confirm-modal.component';
import {
  Router
} from '@angular/router';
import {
  DeleteModalComponent
} from '../delete-modal/delete-modal.component';
import {
  Clipboard
} from '@angular/cdk/clipboard';
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-add-policy-modal',
  templateUrl: './add-policy-modal.component.html',
  styleUrls: ['./add-policy-modal.component.scss']
})
export class AddPolicyModalComponent {
  allowToken: any;
  output: any;
  form: FormGroup;
  matDialogDeleteRef: MatDialogRef < ConfirmModalComponent > | undefined;
  kmsArn: any;
  loading: boolean = false;
  submitted: any;
  public error: any;
  errormsg: boolean = false;
  policyUpdate:boolean = false;
  eMsg: string ="";
  policyError: boolean = false;
  accountName: any;

  constructor(private apiService:ApiService,private clipboard: Clipboard, private dialogRef: MatDialogRef < AddPolicyModalComponent > , private myRoute: Router, @Inject(MAT_DIALOG_DATA) public data: string, private formBuilder: FormBuilder, private matDialog: MatDialog, private dialogRefs: MatDialogRef < DeleteModalComponent > ) {
    this.output = data
    this.form = new FormGroup({
      principalArn: new FormControl('', Validators.required),
      tokenArn: new FormControl(this.output.token)
    });
  }
  ngOnInit() {}
  get f() {
    return this.form.controls;
  }
  //Add Policy
    addPolicyArn()
    {
        if (this.form.valid) {
            this.submitted = true;
            if (this.output.account === "Dev") {
                this.accountName="dev"
            }
            else if(this.output.account === "Dev-dp"){
                this.accountName="dev-dp"
            }
            else{
                this.accountName='stg/prod'
            }
            this.form.patchValue({
                tokenArn: this.output.token
            });
            this.apiService.tokenAllowPolicy(this.form.value,this.accountName).subscribe((response: any[]) => {
                    this.loading = true;
                    this.allowToken = response;
                    this.loading = true;
                    this.kmsArn = this.allowToken.kmsKeyArn;
                    if(this.kmsArn){
                        this.policyUpdate = true;
                        this.policyError = false;
                    }
                    else{
                        this.policyUpdate = false;
                        this.policyError = true;
                        this.eMsg = this.allowToken.errorMessage;
                        console.log("Token Allow Policy Error Msg",this.eMsg);
                    }
                },
                (error: any) => {
                    console.log('Add Policy Failed', error)
                    this.errormsg = true
                }
            );
        }
    }

  public copyToClipboardWithParameter(value: HTMLElement): void {
    const text: string = value.textContent || '';
    console.log(text);
    const successful = this.clipboard.copy(text);

    if (successful) {
      // do something
    } else {
      // do something else 
    }
  }
}