import {
  Component, Inject
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  HecateService
} from 'src/app/services/hecate.service';
import {
  Clipboard
} from "@angular/cdk/clipboard";
import {
  Location
} from '@angular/common';
import {
  MatTableDataSource
} from '@angular/material/table';
import {
  M365CertificateData
} from '../apps-list/apps-list.component';
import { LoaderService } from 'src/app/services/loader.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { appResponse } from '../AppResponse';
import {
  environment
} from 'src/environments/environment';

export interface certDetailInfo {
  template: Templates[];
  providerKeyList: ProviderKeyList[];
}
export interface Templates {
  provider: string;
  classId: string;
  templateId: string;
  productName: string;
  m365Config: Object;
  skipRotation: boolean;
  skipMonitor: boolean;
  skipTenantValidation: boolean;
}

export interface ProviderKeyList {
  providerKeyId: string;
  publicCertificate: string;
  expiresAt: Object;
  createdAt: Object;
}

export interface appData {
  classId: string;
  m365Config: {
    allowedUriPrefixes: string;
    applicationId: string;
    applicationName: string;
    environment: string;
    objectId: string;
    tenantId: string;
  }
  productName: string;
  provider: string;
  skipMonitor: string;
  skipRotation: string;
  skipTenantValidation: string;
  templateId: string;
}

export interface GWSCertificateData {
  template: appData;
  serIdwithKeys: []
}

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})

export class InfoModalComponent {
  displayedColumns = ['providerKeyId', 'expiresAt', 'createdAt'];
  displayedGWSColumns = ['ServiceAccId', 'ProviderKeyId', 'CreatedDate', 'CertExpiry'];
  appsDataSource: MatTableDataSource < appData > = new MatTableDataSource;
  appsGWSDataSource: MatTableDataSource < appData > = new MatTableDataSource;
  appswithM365CerificateDataSources : MatTableDataSource <M365CertificateData> = new MatTableDataSource;
  appswithGWSCerificateDataSource: MatTableDataSource < GWSCertificateData > = new MatTableDataSource;
  GWSCertList: GWSCertificateData[] = [];
  M365List: appData[] = [];
  GWSList: appData[] = [];
  allCert: any;
  isAppConfigM365Info: boolean = false;
  isAppGWCInfo: boolean = false;
  isAppRedirectInfo: boolean = false;
  isCertificateInfo: boolean = false;
  isCertGWSInfo: boolean = false;
  showButton: boolean = false;
  h: any;
  cId: any;
  templateId: any;
  classId: any;
  appConfig: any;
  isAppConfigGWSInfo: any;
  certList: any;
  redirectInfoList: any;
  reInfoList: any;
  reInfoLists: boolean = false;
  certData: any;
  pro: any[] = [];
  provider: any;
  loading: boolean= false;
  loadbar: boolean = false;
  matDialogInfoRef: MatDialogRef<InfoModalComponent> | undefined;
  output: any;
  eMsg: boolean = false;
  errormsg: any;
  ecatchMsg: boolean = false;
  gwseMsg: boolean = false;
  certDetails: boolean = false;
  isAppInfo: boolean = false;
  environmentName: any;
  isDev: boolean = false;
  isStg: boolean = false;
  isProd: boolean = false;
  regionValue:any;
  
  constructor( @Inject(MAT_DIALOG_DATA) public data: string,private matDialog: MatDialog,private hecateService: HecateService, private router: Router, private clipboard: Clipboard, private location: Location,private loaderService :LoaderService) {
    this.output = data;
    {
      this.loaderService.isLoading.subscribe((v) => {
          this.loading = v;
      });
    }
   
    this.environmentName = environment.env_name
    if (this.environmentName === 'dev') {
        this.isDev = true;
    }
    if (this.environmentName === 'stg') {
        this.isStg = true;
    }
    if (this.environmentName === 'prod') {
        this.isProd = true;
    }
  }
  
  ngOnInit() {
    this.classId = this.hecateService.classId;
    this.templateId = this.hecateService.templateId;
    this.provider = this.hecateService.provider;
    this.regionValue=this.output.regionValue;
    if (this.output.info === 'app-config') {
      this.appConfigData(this.classId, this.templateId);
      this.showButton = true
    }
    if (this.output.info === 'app-redirect') {
      this.redirectInfo(this.classId);
      this.showButton = true
    }
    if (this.output.info == 'certificate-details') {
      this.certInfo(this.classId, this.templateId);
      this.showButton = true
    }
  }
  
  //++++++++++++++++++ App config +++++++++++++++++++
  appConfigData(classId, templateId) {
    this.loaderService.show();
    this.loadbar = true;
    this.isAppInfo = true;
    this.hecateService.getAppConfig(classId, templateId,this.regionValue).subscribe((response) => {
      this.appConfig = response;
      //to display data in table based on provider i.e M365 & GWS
      if (this.appConfig.provider === 'M365') {
        this.isAppConfigM365Info = true;
      };
      if (this.appConfig.provider === 'M365_GCC') {
        this.isAppConfigM365Info = true;
      };
      if(this.appConfig.provider === 'GWS'){
        this.isAppConfigGWSInfo = true;
      }   
      this.loaderService.hide();
      this.loadbar = false;
    })
  }
  
  //++++++++++++++++++ Redirect Domain config +++++++++++++++++++
  redirectInfo(classId) {
    this.loaderService.show();
    this.loadbar = true;
    this.isAppRedirectInfo = true;
    this.hecateService.getRedirectInfo(classId,this.regionValue).subscribe((response) => {
      //to display redirect domain info in modal
      this.redirectInfoList = response;
      if (this.redirectInfoList == '') {
        this.reInfoLists = true;
      } else {
        this.reInfoLists = false;
      }
      const reInfo = this.redirectInfoList.values();
      for (const value of reInfo) {
        this.reInfoList = value;
        if (this.redirectInfoList == '') {
          this.reInfoLists = true;
        } else {
          this.reInfoLists = false;
        }
      }
      this.loaderService.hide();
      this.loadbar = false;
    })  
  }
  
  //++++++++++++++++++ Certificate Details +++++++++++++++++++
  certInfo(classId, templateId) {
    this.loaderService.show();
    this.loadbar = true;
    this.certDetails = true;
    this.hecateService.getCertDetailsInfo(classId, templateId,this.regionValue).subscribe((response) => {
      this.certList = response;
      if(!Object.keys(response).length){
         this.gwseMsg = true;
         this.loaderService.hide();
         this.loadbar = false;
      }
      if(this.certList.errorMessage || this.certList == '' || this.certList.length == 0 ){
        this.eMsg = true;
        this.errormsg =  this.certList.errorMessage;
        this.loaderService.hide();
        this.loadbar = false;
      }
      // to display certificate details for M365 & GWS
      if (this.certList.template.provider === 'M365') {
        this.loaderService.show();
        this.loadbar = true;
        this.isCertificateInfo = true;
        this.isCertGWSInfo = false;
        this.certData = this.certList.providerKeyList;
        this.appswithM365CerificateDataSources = new MatTableDataSource < M365CertificateData > (this.certData);
      } else {
        this.loaderService.show();
        this.loadbar = true;
        this.isCertificateInfo = false;
        this.isCertGWSInfo = true;
        this.certData = this.certList.serIdwithKeys;
        this.appswithGWSCerificateDataSource = new MatTableDataSource < GWSCertificateData > (this.certData);
      }
      this.loaderService.hide();
      this.loadbar = false;
    },(error) => {
      this.ecatchMsg = true;
      this.loaderService.hide();
      this.loadbar = false;
      this.errormsg = error.errorMessage;    
  });
  }
}