<!-- Tab Development -->
<div class="loader-container">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>
<br>
<div *ngIf="tokenListFail">
    <label style="color:red; font-size: medium; text-align: center; padding-left: 30px; padding-right:10px;">
        Error : Tokens list loading failed please try again
    </label>
</div>
<mat-tab-group (selectedTabChange)="selectedTabChange($event)" *ngIf="isDev">
    <mat-tab label="Account ID: {{this.devAccount}} [{{this.devcount}}]">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <br>
        <div style="display:flex; flex-direction: row;">
            <div class="form-group">
            <input style="margin-left:20px;width:300px;" size="10" (keyup)="applyFilter($event)" name="sender" id="sender" type="text"
                   formControlName="sender" class="form-control" placeholder="Search" required>
        </div>
        </div>
        <br>
        <div class="table-responsive mat-elevation-z8">
            <table mat-table [dataSource]="this.arnDataSource"  matSort #sorter1="matSort">
                <ng-container matColumnDef="ARN">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>ARN</th>
                    <td *matCellDef="let y" mat-cell><b>{{y.ARN}}</b></td>
                </ng-container>
                <ng-container matColumnDef="Sender">
                    <th  *matHeaderCellDef mat-header-cell mat-sort-header="value.sub"> Sender </th>
                    <td  *matCellDef="let y" mat-cell> {{y.value.sub}}</td>
                </ng-container>
                <ng-container matColumnDef="Audience">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="value.aud"> Audience </th>
                    <td mat-cell *matCellDef="let y"> {{y.value.aud}} </td>
                </ng-container>
                <ng-container matColumnDef="Expiration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="value.exp"> Expiration </th>
                    <td mat-cell *matCellDef="let y"> {{y.value.exp}} </td>
                </ng-container>
                <ng-container matColumnDef="Replicate">
                    <th *matHeaderCellDef mat-header-cell ></th>
                    <td *matCellDef="let y" mat-cell>
                        <button (click)="devReplicateTokenStatus(y.ARN)"
                                style="color: white;width:100px">Replication
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Add Policy">
                    <th *matHeaderCellDef mat-header-cell ></th>
                    <td *matCellDef="let y" mat-cell>
                        <button (click)="devAddPolicyToken(y.ARN)" style="color: white;width:100px">Add
                            Policy
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let y" mat-cell>
                        <button (click)="devDeleteToken(y.ARN)"
                                style="color: white;width:100px">Delete
                        </button>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
            </table>
        </div>
    </mat-tab>

    <mat-tab label="Account ID: {{this.devDpAccount}} [{{this.devDpcount}}]">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <br>
        <div style="display:flex; flex-direction: row;">
            <div class="form-group">
                <input style="margin-left:20px;width:300px;" size="10" (keyup)="applyDpFilter($event)" name="sender" id="senderDp" type="text"
                       formControlName="sender" class="form-control" placeholder="Search" required>
            </div>
        </div>
        <br>
        <div class="table-responsive mat-elevation-z8">
            <table mat-table [dataSource]="this.arnDataDpSource"  matSort #sorter2="matSort">
                <ng-container matColumnDef="ARN">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>ARN</th>
                    <td *matCellDef="let y" mat-cell><b>{{y.ARN}}</b></td>
                </ng-container>
                <ng-container matColumnDef="Sender">
                    <th  *matHeaderCellDef mat-header-cell mat-sort-header="value.sub"> Sender </th>
                    <td  *matCellDef="let y" mat-cell> {{y.value.sub}}</td>
                </ng-container>
                <ng-container matColumnDef="Audience">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="value.aud"> Audience </th>
                    <td mat-cell *matCellDef="let y"> {{y.value.aud}} </td>
                </ng-container>

                <ng-container matColumnDef="Expiration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="value.exp"> Expiration </th>
                    <td mat-cell *matCellDef="let y"> {{y.value.exp}} </td>
                </ng-container>
                <ng-container matColumnDef="Replicate">
                    <th *matHeaderCellDef mat-header-cell ></th>
                    <td *matCellDef="let y" mat-cell>
                        <button (click)="devDpReplicateTokenStatus(y.ARN)"
                                style="color: white;width:100px">Replication
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Add Policy">
                    <th *matHeaderCellDef mat-header-cell ></th>
                    <td *matCellDef="let y" mat-cell>
                        <button (click)="devDpAddPolicyToken(y.ARN)" style="color: white;width:100px">Add Policy
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let y" mat-cell>
                        <button (click)="devDpDeleteToken(y.ARN)"
                                style="color: white;width:100px">Delete
                        </button>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
            </table>
        </div>
    </mat-tab>
</mat-tab-group>

<!--STG/PROd Table-->
<mat-tab-group (selectedTabChange)="selectedTabChange($event)" *ngIf="isStg || isProd">
    <mat-tab label="Account ID: {{this.accountId}} [{{this.accountCount}}]">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <br>
        <div style="display:flex; flex-direction: row;">
            <div class="form-group">
                <input style="margin-left:20px;width:300px;" size="10" (keyup)="applyFilter($event)" name="sender" id="senders" type="text"
                       formControlName="sender" class="form-control" placeholder="Search" required>
            </div>
        </div>
        <br>
        <div class="table-responsive  mat-elevation-z8">
            <table mat-table [dataSource]="this.arnDataSource"  matSort #sorter1="matSort">
                <ng-container matColumnDef="ARN">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> ARN</th>
                    <td *matCellDef="let y" mat-cell><b>{{y.ARN}}</b></td>
                </ng-container>
                <ng-container matColumnDef="Sender">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="value.sub"> Sender </th>
                    <td mat-cell *matCellDef="let y"> {{y.value.sub}}</td>
                </ng-container>
                <ng-container matColumnDef="Audience">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="value.aud"> Audience </th>
                    <td mat-cell *matCellDef="let y"> {{y.value.aud}} </td>
                </ng-container>

                <ng-container matColumnDef="Expiration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="value.exp"> Expiration </th>
                    <td mat-cell *matCellDef="let y"> {{y.value.exp}} </td>
                </ng-container>
                <ng-container matColumnDef="Replicate">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let y" mat-cell>
                        <button (click)="ReplicateTokenStatus(y.ARN)"
                                style="color: white;width:100px">Replication
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Add Policy">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let y" mat-cell>
                        <button (click)="AddPolicyToken(y.ARN)" style="color: white;width:100px">Add
                            Policy
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let y" mat-cell>
                        <button (click)="DeleteToken(y.ARN)"
                                style="color: white;width:100px">Delete
                        </button>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
            </table>
        </div>
    </mat-tab>
</mat-tab-group>