export const environment = {
    production: false,
    env_name: 'dev',
    API_URL: 'https://ctp-static-tool-ui.mimecastidentity-dev.com/',
    BASE_URL: 'https://xraj14we85.execute-api.eu-west-2.amazonaws.com/dev',
    BASE_DP_URL: 'https://o76so4bws4.execute-api.eu-west-2.amazonaws.com/dev-dp',
    Token_URL: 'https://ctp-static-tokens-tool-dev.auth.eu-west-2.amazoncognito.com/oauth2',
    response_type: 'code',
    client_id: '6sdu335afvip2j230lj3n68hth',
     secret_key: 'fqnq8dsbu16n8stpnqm1k0s0kdc091klb6dv5oif59orvuh3bh5',
    scope: 'ctp-static-tokens-api/read ctp-static-tokens-api/write email openid',
    redirect_uri: 'https://ctp-static-tool-ui.mimecastidentity-dev.com',
    LOGIN_URL: 'https://ctp-static-tokens-tool-dev.auth.eu-west-2.amazoncognito.com/login',
    Hecate_Base_Url:'https://lls3wykohg-vpce-06fa0f36aaca152d7.execute-api.eu-west-2.amazonaws.com/dev/v1',
    Hecate_ca_central_1_Url:'',
        Hecate_us_east_1_Url:'',
        Hecate_us_west_2_Url:'',
        Hecate_af_south_1_Url:'',
        Hecate_ap_southeast_2_Url:'',
        Hecate_eu_central_1_Url:'',
    Region: [
        {
            value: '1',
            regionValue: 'eu-west-2'
        },
            {
                value: '2',
                regionValue: 'eu-central-1'
            },
            {
                value: '3',
                regionValue: 'us-east-1'
            },
            {
                value: '4',
                regionValue: 'ca-central-1'
            },
            {
                value: '5',
                regionValue: 'af-south-1'
            },
            {
                value: '6',
                regionValue: 'us-west-2'
            },
            {
                value: '7',
                regionValue: 'ap-southeast-2'
            },
    ]
};