<div class="loader-container" *ngIf="loadbar">
  <mat-progress-bar color="black" mode="indeterminate"></mat-progress-bar>
</div>

<div style="width:1200px">
  <h2 mat-dialog-title style="align-items: center; margin-left: 450px;"  *ngIf="this.isAppInfo"><b>Application Config Details</b></h2>
  <h2 mat-dialog-title style="align-items: center; margin-left: 450px;"  *ngIf="this.isAppRedirectInfo"><b>Redirect Domain Details</b></h2>
  <h2 mat-dialog-title style="align-items: center; margin-left: 500px;"  *ngIf="this.certDetails"><b>Certificate Details</b></h2>
  <hr>
  <br>

  <!-- M365 app config -->
  <div style="margin-left:300px" *ngIf="this.isAppConfigM365Info">
    <div style="margin-top: 15px;">
      <div class="display"> <b>Provider :</b> &nbsp; {{   appConfig.provider }} </div>
      <div class="display"><b> Class ID : </b>&nbsp;{{ appConfig.classId }} </div>
      <div class="display"> <b>Template ID : </b>&nbsp;{{ appConfig.templateId }} </div>
      <div class="display"><b> Product Name : </b>&nbsp;{{ appConfig.productName }} </div>
      <div class="display"><b> M365 Config  </b> </div>
      <div class="displaym365"> <b>Environment : </b>&nbsp;{{ appConfig.m365Config.environment }} </div>
      <div class="displaym365" *ngIf="this.isProd "> <b>Environments : </b>&nbsp;{{ this.appConfig.m365Config.environments }} </div>
      <div class="displaym365"> <b>Tenant Id : </b>&nbsp;{{ appConfig.m365Config.tenantId }} </div>
      <div class="displaym365"> <b>Application Id : </b>&nbsp;{{ appConfig.m365Config.applicationId }} </div>
      <div class="displaym365"><b> Application Name : </b>&nbsp;{{ appConfig.m365Config.applicationName }} </div>
      <div class="displaym365"><b> Object Id : </b>&nbsp;{{ appConfig.m365Config.objectId }} </div>
      <div class="displaym365"><b> Allowed Uri Prefixes : </b>&nbsp;{{ appConfig.m365Config.allowedUriPrefixes }} </div>
      <div class="display"><b> Skip Rotation : </b>&nbsp;{{ appConfig.skipRotation }} </div>
      <div class="display"> <b>Skip Monitor : </b>&nbsp;{{ appConfig.skipMonitor }} </div>
      <div class="display"><b> Skip Tenant Validation : </b>&nbsp;{{ appConfig.skipTenantValidation }} </div>
      <br>
    </div>
  </div>

  <!-- GWS app config -->
  <div style="margin-left:300px" *ngIf="this.isAppConfigGWSInfo">
    <div style="margin-top: 15px;">
      <div class="display"> <b>Provider :</b> &nbsp; {{appConfig.provider}} </div>
      <div class="display"><b> Class ID : </b>&nbsp;{{appConfig.classId}} </div>
      <div class="display"> <b>Template ID : </b>&nbsp;{{appConfig.templateId}} </div>
      <div class="display"><b> Product Name : </b>&nbsp;{{appConfig.productName}} </div>
      <div class="display"><b> GWS Config  </b> </div>
      <div class="displaym365"><b> Project Id : </b>&nbsp;{{appConfig.gwsConfig.projectId}} </div>
      <div class="displaym365"><b> Scopes : </b>&nbsp;{{ appConfig.gwsConfig.scopes }} </div>
      <div class="display"><b> Skip Rotation : </b>&nbsp;{{ appConfig.skipRotation }} </div>
      <div class="display"> <b>Skip Monitor : </b>&nbsp;{{ appConfig.skipMonitor }} </div>
      <div class="display"><b> Skip Tenant Validation : </b>&nbsp;{{ appConfig.skipTenantValidation }} </div>
      <br>
    </div>
  </div>

  <!-- redirect domain details-->
  <div style="margin-left:350px"  *ngIf="this.isAppRedirectInfo">
    <label class="display" style="width: 150px;" *ngIf="!this.reInfoLists" >Redirect Domain Url : </label>
    <span style="width: 250px;"  *ngIf="!this.reInfoLists">{{this.reInfoList}}</span>
    <label class="display" *ngIf="this.reInfoLists" style="font-size: medium;color: red; margin-left: 60px;">Error Message : </label>
    <label class="display" *ngIf="this.reInfoLists" style="font-size: medium;color: black; margin-left: 10px;">Redirect Domain Url is empty</label>
    <br><br>
  </div>

  <!-- M365 cert details-->
  <label class="display" *ngIf="this.eMsg" style="font-size: medium;color: red; margin-left: 60px;">
    Error Message :
  </label>
  <span style="width:180px"  *ngIf="this.eMsg">{{this.errormsg}}</span>
  <div style="margin-left:60px"  *ngIf="this.isCertificateInfo">
    <table mat-table [dataSource]="this.appswithM365CerificateDataSources" matSort #sorter1="matSort">
      <ng-container matColumnDef="providerKeyId">
        <th *matHeaderCellDef mat-header-cell >Provider Key Id</th>
        <td *matCellDef="let y" mat-cell>{{y.providerKeyId}}</td>
      </ng-container>
      <ng-container matColumnDef="expiresAt">
        <th *matHeaderCellDef mat-header-cell >Expiry Date</th>
        <td *matCellDef="let y" mat-cell>{{y.expiresAt.seconds * 1000 | date: 'yyyy/MM/dd h:mm:ss a  zzzz'}}</td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th *matHeaderCellDef mat-header-cell >Created Date</th>
        <td *matCellDef="let y" mat-cell>{{y.createdAt.seconds * 1000 | date: 'yyyy/MM/dd h:mm:ss a zzzz'}}</td>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
  </div>

  <!-- GWS cert details -->
  <label class="display" *ngIf="this.gwseMsg" style="font-size: medium;color: red; margin-left: 60px;">
    Error Message :
  </label>
  <span style="width:180px"  *ngIf="this.gwseMsg">Certificate does not exist for this product
</span>
  <div style="margin-left:20px" *ngIf="this.isCertGWSInfo">
    <table mat-table [dataSource]="this.appswithGWSCerificateDataSource" matSort #sorter1="matSort">
      <ng-container matColumnDef="ServiceAccId">
        <th *matHeaderCellDef mat-header-cell>Service Acc Id</th>
        <td *matCellDef="let y" mat-cell>{{y.ServiceAccId}}</td>
      </ng-container>
      <ng-container matColumnDef="ProviderKeyId">
        <th *matHeaderCellDef mat-header-cell>Provider Key Id</th>
        <td *matCellDef="let y" mat-cell>
          <div class="align">
            <div *ngFor="let g of y.ProviderKeys">
              {{g.providerKeyId}}
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="CreatedDate">
        <th *matHeaderCellDef mat-header-cell>Created Date</th>
        <td *matCellDef="let y" mat-cell>
          <div class="align">
            <div *ngFor="let g of y.ProviderKeys">
              {{g.createdAt.seconds * 1000 | date: 'yyyy/MM/dd h:mm:ss a zzzz'}}
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="CertExpiry">
        <th *matHeaderCellDef mat-header-cell >Expiry Date</th>
        <td *matCellDef="let y" mat-cell>
          <div class="align">
            <div *ngFor="let g of y.ProviderKeys">
              {{g.expiresAt.seconds * 1000 | date: 'yyyy/MM/dd h:mm:ss a zzzz'}}
            </div>
          </div>
        </td>
      </ng-container>
      <tr *matHeaderRowDef="displayedGWSColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedGWSColumns;" mat-row></tr>
    </table>
  </div>
  <hr>
  <div align="end" mat-dialog-actions>
  <span>
    <button [mat-dialog-close]="true" color="primary" mat-flat-button style="margin-left:100px;margin-right: 20px;"
            type="button">Close
    </button>
  </span>
  </div>
</div>

