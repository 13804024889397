import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormControl,
  FormGroup,
} from '@angular/forms';
import {
  HttpClient,
} from '@angular/common/http';
import {
  Router
} from '@angular/router';
import {
  Clipboard
} from '@angular/cdk/clipboard';
import {
  environment
} from 'src/environments/environment';
import { LoaderService } from '../services/loader.service';
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-create-static-token',
  templateUrl: './create-static-token.component.html',
  styleUrls: ['./create-static-token.component.scss']
})

export class CreateStaticTokenComponent {
  form = new FormGroup({
    sender: new FormControl(''),
    audience: new FormControl('')
  });
  data: any;
  kmsKeyArn: any;
  list: any;
  tokenArn: any;
  tokendetail: any;
  loading: boolean = false;
  tokenFlag: boolean = false;
  eMsg:string ="";
  tokenFailure: boolean = false;
  errormsg: boolean = false;
  environmentName: any;
  accountValue: any;
  isDev: boolean = false;
  accountName:any;

  constructor(private apiService :ApiService,private loaderService: LoaderService, private myRoute: Router, private clipboard: Clipboard,private httpClient: HttpClient, private router: Router)
  {
    this.loaderService.isLoading.subscribe((v) => { 
      this.loading = v; 
  });
    this.environmentName = environment.env_name
    if (this.environmentName === 'dev') {
      this.isDev = true;
    }
  }

  ngOnInit(): void {}
 reloadPage() {
    window.location.reload()
  }
  radioButtonChange(event)
  {
    this.accountValue=event.value
  }

    createToken(data: any)
    {
        if (this.form.valid) {
            console.log(this.form.value);
            this.form.controls['sender'].disable();
            this.form.controls['audience'].disable();
            if(this.accountValue === '1'){
                this.accountName="dev"
            }
            if(this.accountValue === '2'){
                this.accountName="dev-dp"
            }
            if(this.environmentName === 'stg' || this.environmentName === 'prod') {
                this.accountName='Stg/prod';
            }
            this.apiService.createToken(data,this.accountName).subscribe((response: any) => {
                this.loading = true;
                this.tokendetail = response;
                this.loading = false;
                this.kmsKeyArn = this.tokendetail.kmsKeyArn;
                this.tokenArn = this.tokendetail.tokenArn;
                //negative scenario if error while creating token
                if(this.tokendetail.tokenArn){
                    this.tokenFlag = true;
                    this.tokenFailure = false;
                }
                else{
                    this.tokenFlag = false;
                    this.tokenFailure = true;
                    this.eMsg = this.tokendetail.errorMessage;
                }
            },(error: any) => {
                console.log('Create Token Failed', error)
                this.errormsg = true
                this.myRoute.navigate(["home"]);
            } );
        }
    }
  //copy Arn
  public copyToClipboardWithParameter(value: HTMLElement): void {
    const text: string = value.textContent || '';
    const successful = this.clipboard.copy(text);

  }




}