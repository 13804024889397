import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreateStaticTokenComponent } from './create-static-token/create-static-token.component';
import { ListStaticTokenComponent } from './list-static-token/list-static-token.component';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { HomeComponent } from './home/home.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { NavComponent } from './nav/nav.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from './auth.service';
import { AuthGuard } from './shared/auth.guard';
import { MatSelectModule } from '@angular/material/select';
import { ReplicateModalComponent } from './replicate-modal/replicate-modal.component';
import { AddPolicyModalComponent } from './add-policy-modal/add-policy-modal.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from './services/loader.service';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './timeout.interceptor';
import { LoaderInterceptor } from './loader.interceptor';
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatIconModule} from "@angular/material/icon";
import {MatGridListModule} from '@angular/material/grid-list';
import { AppsListComponent } from './Hecate_2.0/apps-list/apps-list.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InfoModalComponent } from './Hecate_2.0/info-modal/info-modal.component';



@NgModule({
  declarations: [
    AppComponent,
    CreateStaticTokenComponent,
    ListStaticTokenComponent,
    HomeComponent,
    LoginComponent,
    ReplicateModalComponent,
    AddPolicyModalComponent,
    DeleteModalComponent,
    NavComponent,
    ConfirmModalComponent,
    AppsListComponent,
    InfoModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatInputModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatListModule,
    MatTableModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    MatSelectModule,
    MatDialogModule,
    MatTableModule,
    MatTabsModule,
    MatRadioModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatGridListModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 30000 },
    LoaderService,
    AuthService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


