import {
    Component,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {
    MatTableDataSource,
    MatTableDataSourcePaginator
} from '@angular/material/table';
import {
    Router
} from '@angular/router';
import {
    HecateService
} from 'src/app/services/hecate.service';
import {
    LoaderService
} from 'src/app/services/loader.service';
import {
    environment
} from 'src/environments/environment';
import {
    MatPaginator
} from "@angular/material/paginator";
import {
    MatSort,
    Sort
} from "@angular/material/sort";
import {
    MatDialog,
    MatDialogRef
} from "@angular/material/dialog";
import { InfoModalComponent } from '../info-modal/info-modal.component';

export interface appData {
    classId: string;
    m365Config: {
        allowedUriPrefixes: string;
        applicationId: string;
        applicationName: string;
        environment: string;
        objectId: string;
        tenantId: string;
    }
    productName: string;
    provider: string;
    skipMonitor: string;
    skipRotation: string;
    skipTenantValidation: string;
    templateId: string;
}

export interface M365CertificateData {
    template: appData;
    providerKeyList: [];
}

export interface GWSCertificateData {
    template: appData;
    serIdwithKeys: []
}

@Component({
    selector: 'app-apps-list',
    templateUrl: './apps-list.component.html',
    styleUrls: ['./apps-list.component.scss']
})
export class AppsListComponent {
    displayedColumns = ['Name', 'Class Id', 'Template Id', 'CertificateExpiryDate', 'APP Config Info', 'APP Redirect Domain Info', 'Certificate Details'];
    displayedGWSColumns = ['Name', 'Class Id', 'Template Id', 'MasterAccId', 'MasterAccIdExpirtDate', 'APP Config Info', 'Certificate Details'];
    loading: any;
    environmentName: any;
    isDev: boolean = false;
    isStg: boolean = false;
    isProd: boolean = false;
    info: any;
    regions= environment.Region;
    sort: Sort =new MatSort;
    appsDataSource: MatTableDataSource < appData > = new MatTableDataSource;
    appsGWSDataSource: MatTableDataSource < appData > = new MatTableDataSource;
    appswithM365CerificateDataSource: MatTableDataSource < M365CertificateData > = new MatTableDataSource;
    appswithGWSCerificateDataSource: MatTableDataSource < GWSCertificateData > = new MatTableDataSource;
    paginator: any;
    appList: any;
    M365List: appData[] = [];
    GWSList: appData[] = [];
    allCert: any;
    M365CertList: M365CertificateData[] = [];
    GWSCertList: GWSCertificateData[] = [];
    list1: [] = [];
    list2: []  =[];
    hecateAppList: any;
    matDialogInfoRef: MatDialogRef<InfoModalComponent> | undefined;
    certList: any;
    hecateCertList: any;
    @ViewChildren(MatPaginator) paginatorcert = new QueryList < MatPaginator > ();
    loadbar: boolean = false;
    errormsg: any;
    eMsg: boolean = false;
    dataLoad:boolean = false;
    gwsData: boolean = false;
    name: any;
    m365Data: boolean = false;
    regionValue:any;
    
    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.setDataSourceAttributes();
    }

    constructor(private matDialog: MatDialog,private hecateService: HecateService, private loaderService: LoaderService, private router: Router) {
        {
            this.loaderService.isLoading.subscribe((v) => {
                this.loading = v;
            });
        }
        this.environmentName = environment.env_name
        if (this.environmentName === 'dev') {
            this.isDev = true;
        }
        if (this.environmentName === 'stg') {
            this.isStg = true;
        }
        if (this.environmentName === 'prod') {
            this.isProd = true;
        }
    }

    ngOnInit() {
       this.loaderService.show();
       this.loadbar = true;
        this.getGWSCertList(this.regionValue);
        this.getHecateAppList(this.regionValue);
        this.appsDataSource.sortingDataAccessor = (item, property) => {
            if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
            return item[property];
        };
        this.router.onSameUrlNavigation = 'reload';
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

 ngAfterViewInit() {
        this.appswithM365CerificateDataSource.paginator = this.paginatorcert.toArray()[0];
        this.appswithGWSCerificateDataSource.paginator = this.paginatorcert.toArray()[1];

    }
    setDataSourceAttributes() {}

// filter on M365 table
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.appswithM365CerificateDataSource.filter = filterValue.trim().toLowerCase();
    }

//filter on GWS table
    applyGWSFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
       
        if (filterValue.startsWith('mc')) {
            
            this.filterByServiceAccId(filterValue)
        } else if (filterValue.includes('[UTC]')) {
            
            this.filterByExpiryDate(filterValue)
        } 
        else  {
         let finalArray: any = [];
         finalArray= this.filterByCustomerAccId(filterValue)
         if(finalArray.length==0){
          this.appswithGWSCerificateDataSource.filter = filterValue.trim().toLowerCase();
         }
         else{
           this.appswithGWSCerificateDataSource.data = finalArray; 
         }
     }     
    }
//to Reload GWS certificates
    reloadCertPage(){
        this.getGWSCertList(this.regionValue);
        this.eMsg = false;
   }

//to get the Hecate apps list
    getHecateAppList(regionValue) {
        this.m365Data = true;
         this.appList=null;
         this.M365List=[];
         this.GWSList=[];
         this.hecateService.getAppsList(regionValue).subscribe((response) => {
            this.loaderService.show();
            this.loadbar= true;
            this.hecateAppList = JSON.parse(JSON.stringify(response)).body;
            this.appList = JSON.parse(this.hecateAppList);  
            for (let i = 0; i < this.appList.length; i++) {
                if (this.appList[i].provider == "M365") {
                    this.M365List.push(this.appList[i])
                };
                if (this.appList[i].provider == "M365_GCC") {
                    this.M365List.push(this.appList[i])
                };
                if(this.appList[i].provider == "GWS") {
                    this.GWSList.push(this.appList[i])
                };
            }
            if(this.appList){
                this.m365Data = false; 
            }
            this.appsGWSDataSource = new MatTableDataSource < appData > (this.GWSList)
           
            if (response.statusCode == 200) {
                this.getM365CertList(this.regionValue);
            }
            this.getAllCertificateInfo();
        })
    }

//to open App Config Modal
    AppConfigInfo(classId, templateId) {
        this.info = "app-config"
        this.hecateService.classId = classId;
        this.hecateService.templateId = templateId;
        this.matDialogInfoRef = this.matDialog.open(InfoModalComponent, {
           data:{
            info: "app-config",
            regionValue:this.regionValue,
           },
            disableClose: true
        });
        this.matDialogInfoRef.afterClosed().subscribe((res: any) => {
            if ((res == true)) {
                this.name = "";
            }
    });
}

//to open App Redirect Info Modal
    AppRedirectInfo(classId) {
        this.info = "app-redirect"
        this.hecateService.classId = classId;
        this.matDialogInfoRef = this.matDialog.open(InfoModalComponent, {
        data:{
         info: "app-redirect",
         regionValue:this.regionValue,
        },
         disableClose: true
     });
     this.matDialogInfoRef.afterClosed().subscribe((res: any) => {
         if ((res == true)) {
             this.name = "";
         }
      });
   }

  //to open Certificate Details Modal
    AppCertificateInfo(classId, templateId, provider) {
        this.info = "certificate-details"
        this.hecateService.classId = classId;
        this.hecateService.templateId = templateId;
        this.hecateService.provider = provider;
        this.matDialogInfoRef = this.matDialog.open(InfoModalComponent, {
        data:{
         info: "certificate-details",
         regionValue:this.regionValue,   
        },
         disableClose: true
     });
     this.matDialogInfoRef.afterClosed().subscribe((res: any) => {
         if ((res == true)) {
             this.name = "";
         }
     });
 }
 //to get the M365 certificates
    getM365CertList(regionValue) {
        this.m365Data = true;
        this.M365CertList=[];
        this.hecateService.getAllM365CertList(regionValue).subscribe((response) => {
            this.loaderService.show();
            this.loadbar= true;
            this.hecateCertList = JSON.parse(JSON.stringify(response)).body;
            this.M365CertList = JSON.parse(this.hecateCertList);
            if(this.M365CertList){
                this.m365Data = false; 
            }
            this.getAllCertificateInfo();
            this.loaderService.hide();
            this.loadbar= false;
        })
    }

 //to get the GWS certificates
    getGWSCertList(regionValue) {
        this.gwsData = true;
        this.GWSCertList=[];
        this.hecateService.getAllGWSCertList(regionValue).subscribe((response) => {
            
            this.loaderService.show();
            this.loadbar= true;
            this.hecateCertList = JSON.parse(JSON.stringify(response)).body;
            this.GWSCertList = JSON.parse(this.hecateCertList);
            if(this.GWSCertList || this.hecateCertList ===''){
                this.gwsData = false; 
            }
            this.getAllCertificateInfo();
            this.dataLoad = true;
            this.loaderService.hide();
            this.loadbar= false;
        },(error) => {
            
            this.eMsg = true;
            this.errormsg = error.message;
            
        })
    }
//to map the certificate response with table
    getAllCertificateInfo() {
        this.list1=[];
        this.list2=[];
        this.loaderService.show();
        this.loadbar= true;
        this.list1 = this.mergeCertificatesByClassID(this.M365List, this.M365CertList)
        console.log("M365List",this.list1);
        this.appswithM365CerificateDataSource = new MatTableDataSource < M365CertificateData > (this.list1)
        this.list2 = this.mergeCertificatesByClassID(this.GWSList, this.GWSCertList)
        console.log("GWSList",this.list2);
        this.appswithGWSCerificateDataSource = new MatTableDataSource < GWSCertificateData > (this.list2);
        this.appswithM365CerificateDataSource.paginator = this.paginatorcert.toArray()[0];
        this.appswithGWSCerificateDataSource.paginator = this.paginatorcert.toArray()[1];
        this.loaderService.hide();
        this.loadbar= false;
    }

    selectedTabChange(event: any) {}

    mergeCertificatesByClassID = (a1, a2) =>
        a1.map(itm => ({
            ...a2.find((item) =>
                (item.template.classId === itm.classId) && item),
            ...itm
        }));

    private _compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
 
    //for sorting of tables
    sortData(tablename:String)
    {
       if(tablename==='GWS')
        {
            this.appswithGWSCerificateDataSource.data = this.appswithGWSCerificateDataSource.data.sort((a, b) => {
            const isAsc = this.sort.direction === 'asc';
            return this._compare(a[this.sort.active], b[this.sort.active], isAsc);
           }); 
        }
        if(tablename==='M365')
        {
            this.appswithM365CerificateDataSource.data = this.appswithM365CerificateDataSource.data.sort((a, b) => {
                const isAsc = this.sort.direction === 'asc';
                return this._compare(a[this.sort.active], b[this.sort.active], isAsc);
            });
        }
        
    }

//filter by service account id
    filterByServiceAccId(AccId: String) {
        this.loaderService.show();
        this.loadbar= true;
        const finalArray: any = [];
        const modifiedown = this.list2.map((List2: any) => {
            if (List2.serIdwithKeys !== undefined) {
                const y = List2.serIdwithKeys.filter(x =>
                    x.ServiceAccId === AccId);
                if (y.length !== 0) {
                    finalArray.push(List2)
                }
                if(y.length === 0){
                  this.getM365CertList(this.regionValue);
                }
            }
        })
        this.appswithGWSCerificateDataSource.data = finalArray;
        this.loaderService.hide();
        this.loadbar= false;
    }

//filter by customer account id
       filterByCustomerAccId(AccId: String) {
        const finalArray: any = [];
        const modifiedown = this.list2.map((List2: any) => {
            if (List2.serIdwithKeys !== undefined) {
                const y = List2.serIdwithKeys.filter(x =>
                    x.CustomerAccId === AccId);
                if (y.length !== 0) {
                    finalArray.push(List2)
                }
                if(y.length === 0){
                  this.getM365CertList(this.regionValue);
                }
            }
        })
        return finalArray;
    }

//filter by expiry date
    filterByExpiryDate(date: string) {
        const finalArray1: any = [];
        const modifiedown1 = this.list2.map((List3: any) => {
            if (List3.masterSmCertificates !== undefined) {
                const y1 = List3.masterSmCertificates.filter(z =>
                    z.keyPairAndCertificate.endDate === date);
                if (y1.length !== 0) {
                    finalArray1.push(List3)
                }
            }
        })
        this.appswithGWSCerificateDataSource.data = finalArray1
    }

    onChange(event:Event) {
        console.log((event.target as HTMLInputElement).value);
        this.regionValue=(event.target as HTMLInputElement).value;
        this.getGWSCertList(this.regionValue);
        this.getHecateAppList(this.regionValue);
    }
}