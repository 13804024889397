import {
    Component,
    Inject
} from '@angular/core';
import {
    FormBuilder
} from '@angular/forms';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
    Router
} from '@angular/router';
import {
    ConfirmModalComponent
} from '../confirm-modal/confirm-modal.component';
import {ApiService} from "../services/api.service";

@Component({
    selector: 'app-delete-modal',
    templateUrl: './delete-modal.component.html',
    styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent {
    deleteToken: any;
    output: any;
    tokenForm: any;
    matDialogDeleteRef: MatDialogRef<ConfirmModalComponent> | undefined;
    errormsg: boolean = false;
    accountName: any;
    eMsg: any;

    constructor(private apiService: ApiService, private myRoute: Router, @Inject(MAT_DIALOG_DATA) public data: string, private formBuilder: FormBuilder, private matDialog: MatDialog, private dialogRef: MatDialogRef<DeleteModalComponent>) {
        this.output = data
    }

    ngOnInit() {
        this.tokenForm = this.formBuilder.group({
            tokenArn: [this.output.token]
        });
        this.tokenForm.controls['tokenArn'].disable()
    }

    deleteArn() {
        if(this.output.account === "Dev"){
            this.accountName = "dev"
        }
        else if(this.output.account === "Dev-dp"){
            this.accountName = "dev-dp"
        }
        else{
            this.accountName = 'stg/prod'
        }
        this.apiService.deleteToken(this.output.token, this.accountName).subscribe((response: any[]) => {
            this.deleteToken = response;
            if (this.deleteToken) {
                this.matDialogDeleteRef = this.matDialog.open(ConfirmModalComponent, {
                    data: {
                        operation: "delete"
                    },
                    disableClose: true
                });
                window.location.reload();
            }
        }, (error) => {
            console.error('Error caught in deletion of token')
            this.errormsg = true;
            this.myRoute.navigate(["home"]);
            window.location.reload();
        });
    }
}
